.tab-container {
  position: fixed;
  bottom: 6px;
  left: 0;
  padding: 8px 8px 0 8px;
  box-sizing: border-box;
  width: 100%;
}

.bottom-row {
  background-color: #5D3FD3;
  position: fixed;
  padding: 3px;
  bottom: 0px;
  width: 100%;
}

/* Use Flexbox for the tabs container to spread the buttons evenly */
.tabs {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.tabs li {
  /* Remove the float and let flex handle the layout */
  flex: 1;
  position: relative;
}

.tabs button {
  /* Make the button take the full width of the container */
  display: block;
  width: 100%;
  padding: 10px 0;
  text-decoration: none;

  /* Remove float */
  float: none;

  /* Default colors */
  /* color: white;
  background: #ddc385; */

  /* Only round the top corners */
  -webkit-border-top-left-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-radius-topleft: 15px;
  -moz-border-radius-topright: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/* Other styles remain unchanged */
.tabs .team {
  background: orange;
  color: white;
}

.tabs .mint button {
  background: #5D3FD3;
  color: #ffc700;
}

.tabs .roadmap {
  background: orange;
  color: white;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.tabs .narrative {
  background: green;
  color: white;
}

.tabs .active {
  /* Highest, active tab is on top */
  z-index: 3;
}

/* TODO: Finalize active tab changes */
.tabs .active button {
  /* Colors when tab is active */
}

.tabs li:before,
.tabs li:after, 
.tabs li button:before,
.tabs li button:after {
  /* All pseudo elements are 
     abs. positioned and on bottom */
  position: absolute;
  bottom: 0;
}

/* Only the first, last, and active
   tabs need pseudo elements at all */
.tabs li:last-child:after,   
.tabs li:last-child button:after,
.tabs li:first-child:before, 
.tabs li:first-child button:before,
.tabs .active:after,   
.tabs .active:before, 
.tabs .active button:after, 
.tabs .active button:before {
  content: "";
}

.tabs .active:before, 
.tabs .active:after {
  background: #5D3FD3;
  
  /* Squares below circles */
  z-index: 1;
}

/* Squares */
.tabs li:before {
  background: orange;
  width: 10px;
  height: 10px;
}

.tabs li:after {
  background: green;
  width: 10px;
  height: 10px;
}

.tabs li:before {
  left: -10px;      
}

.tabs li:after { 
  right: -10px;
}

/* Circles */
.tabs li button:after, 
.tabs li button:before {
  width: 20px; 
  height: 20px;
  /* Circles are circular */
  -webkit-border-radius: 10px;
  -moz-border-radius:    10px;
  border-radius:         10px;
  background: white;
  
  /* Circles over squares */
  z-index: 2;
}

/* make background the color of tab to right of active */
.tabs .active button:after {
  background: yellow;
}

/* make background the color of tab to left of active */
.tabs .active button:before {
  background: orange;
}

/* First and last tabs have different
   outside color needs */
.tabs li:first-child.active a:before,
.tabs li:last-child.active a:after {
}

.tabs li button:before {
  left: -20px;
}

.tabs li button:after {
  right: -20px;
}
